// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { AUDIT_LOG_SERVICE_ENDPOINT, LOG_RESOURCE_PATH } from './auditLogServiceConstants';

const auditLogServiceConfig = createHttpServiceConfig(AUDIT_LOG_SERVICE_ENDPOINT, LOG_RESOURCE_PATH);

//-- request interceptors --//
auditLogServiceConfig.addRequestInterceptor(authInterceptor);
/**
 * @param {import('./auditLogServiceTypes').IFetchLogParams} [params]
 */
export const fetchLogsService = (params) => auditLogServiceConfig.http({
    method: 'GET',
    params
});

/**
 * @param {string} id 
 */
export const fetchLogByIdService = (id) => auditLogServiceConfig.http({
    method: 'GET',
    url: `/${id}`
});
